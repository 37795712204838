import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Button,
  IconButton,
} from "@material-tailwind/react";
import LOGO from "../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import useAuth from "../utils/context/auth-context";
import Avatar from "react-avatar";
import useInfo from "../utils/context/info-context";
import useCart from "../utils/context/cart-context";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RiShoppingCart2Line } from "react-icons/ri";
function TopNav() {
  const { storageBucketImageRoot } = useInfo();
  const { auth, ...user } = useAuth();
  const [openNav, setOpenNav] = useState(false);
  const { cart } = useCart();
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="container mx-auto pt-4 lg:p-0 lg flex flex-col gap-2 lg:mb-0 lg:flex-row lg:items-center lg:gap-6">
      <NavLink
        end
        onClick={() => setOpenNav(false)}
        to="/"
        className={(_) =>
          "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-primary" : " text-gray-600")
        }
      >
        Home
      </NavLink>
      <NavLink
        onClick={() => setOpenNav(false)}
        to="/courses"
        className={(_) =>
          "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-primary" : " text-gray-600")
        }
      >
        Courses
      </NavLink>
      <NavLink
        onClick={() => setOpenNav(false)}
        to="/tutors"
        className={(_) =>
          "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-primary" : " text-gray-600")
        }
      >
        Tutors
      </NavLink>
      <NavLink
        onClick={() => setOpenNav(false)}
        to="/institutes"
        className={(_) =>
          "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " +
          (_.isActive ? "text-primary" : " text-gray-600")
        }
      >
        Institutes
      </NavLink>
      <a
        href="https://support.ewings.lk/"
        target="_blank"
        rel="noreferrer"
        className={`mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary text-gray-600`}
      >
        Support
      </a>
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/support"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-gray-600")}
      >
        Support
      </NavLink> */}
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/about-us"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-gray-600")}
      >
        About Us
      </NavLink> */}
    </ul>
  );

  return (
    <div className="fixed w-full z-50">
      <Navbar
        color="white"
        shadow={false}
        blurred={false}
        className={`py-2 px-0 lg:py-4 bg-white rounded-none container mx-auto rounded-b-xl ${
          openNav ? "shadow-lg" : ""
        }`}
      >
        <div className="h-12 flex items-center justify-between px-4 lg:px-8">
          <NavLink to="/" onClick={() => setOpenNav(false)}>
            <img
              src={LOGO}
              alt="Ewings"
              className="w-60 h-10 object-scale-down"
            />
          </NavLink>
          <div className="invisible lg:visible flex-grow">{navList}</div>
          <div className="lg:invisible visible"></div>

          {auth ? (
            <div className="items-center justify-end flex mt-2">
              <NavLink to="/profile" onClick={() => setOpenNav(false)}>
                {user.profile?.avatar_url ? (
                  <img
                    src={"https://api.ewings.lk/" + user.profile?.avatar_url_new}
                    alt={user.fname}
                    className="mx-auto rounded-full w-10 h-10"
                  />
                ) : (
                  <Avatar
                    name={user.fname + " " + user.lname}
                    color="#227AFF"
                    size="40"
                    round={true}
                    className="mx-auto"
                  />
                )}
              </NavLink>
              <h4 className="text-black invisible lg:visible capitalize ml-2 max-w-xs truncate">
                {user.fname} <br />
                <span className="font-light">{user.lname}</span>
              </h4>
              <Link to="/cart">
                <div className="mx-4 relative">
                  <RiShoppingCart2Line className="text-gray-900" size={32} />
                  <p className="bg-primary text-white rounded-full absolute -top-2 -right-2 flex items-center justify-center w-6 h-6 text-sm">
                    {Object.keys(cart).length}
                  </p>
                </div>
              </Link>
            </div>
          ) : (
            <div className="flex items-center justify-end">
              <div className="grid grid-cols-2 gap-2 mt-2">
                <NavLink to="/auth/login" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="outlined"
                    size="sm"
                    color="blue"
                    ripple={true}
                    className="invisible lg:visible font-medium capitalize text-base border-2 button-sm"
                  >
                    <p className="px-2 text-primary">Login</p>
                  </Button>
                </NavLink>
                <NavLink to="/auth/register" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="filled"
                    size="sm"
                    color="blue"
                    ripple={true}
                    className="invisible lg:visible  font-normal capitalize text-base"
                  >
                    <p className="px-2 text-white">Sign Up</p>
                  </Button>
                </NavLink>
              </div>
              <Link to="/cart" className="ml-auto">
                <div className="mx-4 relative">
                  <RiShoppingCart2Line className="text-gray-900" size={32} />
                  <p className="bg-primary text-white rounded-full absolute -top-2 -right-2 flex items-center justify-center w-6 h-6 text-sm">
                    {Object.keys(cart).length}
                  </p>
                </div>
              </Link>
            </div>
          )}

          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:invisible text-gray-700"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav} className="">
          <div className="p-4">
            {auth ? (
              <>
                {/* <NavLink to="/profile" onClick={() => setOpenNav(false)}>
                  <div className="flex items-center justify-start">
                      {user.profile?.avatar_url ? (
                  <img src={storageBucketImageRoot + user.profile?.avatar_url} alt={user.fname} className="mx-auto rounded-full w-10 h-10" />
                ) : (
                  <Avatar name={user.fname + " " + user.lname} color="#227AFF" size="40" round={true} className="mx-auto" />
                )}
                    <h4 className="text-black capitalize">
                      {user.fname} <span className="font-light">{user.lname}</span>
                    </h4>
                  </div>
                </NavLink> */}
              </>
            ) : (
              <div className="grid grid-cols-2 gap-2 mx-auto max-w-xs">
                <NavLink to="/auth/login" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="outlined"
                    size="sm"
                    color="blue"
                    ripple={true}
                    className="font-medium capitalize text-base border-2 button-sm"
                  >
                    <p className="px-2 text-primary">Login</p>
                  </Button>
                </NavLink>
                <NavLink to="/auth/register" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="filled"
                    size="sm"
                    color="blue"
                    ripple={true}
                    className="font-normal capitalize text-base"
                  >
                    <p className="px-2 text-white">Sign Up</p>
                  </Button>
                </NavLink>
              </div>
            )}
            {navList}
          </div>
        </MobileNav>
      </Navbar>
    </div>
  );
}

export default TopNav;
