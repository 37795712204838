import { useQuery } from "@apollo/client";
import { createContext, useContext, useReducer } from "react";
import { _appInfo } from "../gql/basic";
import { IGetAppInfo, IInfoContext } from "../types/types";

const _info = {
  apiName: "",
  apiVersion: "",
  storageBucketVideoRoot: "",
  storageBucketImageRoot: "",
  init: false,
};
export const InfoContext = createContext<IInfoContext>(_info);

const reducer = (state: IInfoContext, action: any) => {
  switch (action.type) {
    case "set":
      return action.payload;
    case "reset":
      return {
        ..._info,
        init: true,
      };
  }
  return state;
};

export const InfoProvider = ({ children }: { children: JSX.Element }) => {
  const [_state, dispatch] = useReducer(reducer, _info);
  InfoContext.displayName = "Info Context";
  useQuery<IGetAppInfo>(_appInfo, {
    onCompleted(data) {
      dispatch({ type: "set", payload: { init: true, ...data.appInfo } });
    },
    onError(error) {
      dispatch({ type: "reset" });
    },
  });

  return <InfoContext.Provider value={_state}>{children}</InfoContext.Provider>;
};

function useInfo() {
  const info = useContext(InfoContext);

  return info;
}

export default useInfo;
