import { useQuery } from "@apollo/client";
import { createContext, useContext, useReducer } from "react";
import { _getStudentMyProfile, _me } from "../gql/auth";
import { IAuthContext, IGetStudentMyProfile, IInfoContext, IMeRes, IUser } from "../types/types";

const _auth: IAuthContext = {
  auth: false,
  init:false,
  _id: "",
  accessToken: "",
  fname: "",
  lname: "",
  email: "",
  role: "",
  status: "",
  created_at: 0,
  updated_at: 0,
  profile: null,
  login: () => {},
  logout: () => {},
  me: () => {},
};
export const AuthContext = createContext<IAuthContext>(_auth);

const reducer = (state: IInfoContext, action: any) => {
  switch (action.type) {
    case "set":
      return {init:true,...action.payload};
    case "reset":
      return {init:true,...action.payload};
  }
  return state;
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [_state, dispatch] = useReducer(reducer, _auth);
  const { refetch } = useQuery<IGetStudentMyProfile>(_getStudentMyProfile, { skip: true });

  AuthContext.displayName = "Auth Context";
  const { refetch: me } = useQuery<IMeRes>(_me, {
    async onCompleted(data) {
      const profile = await refetch();
      const token = localStorage.getItem("_chuck-38e6e39a-ab69-4aac-bb0c");
      await dispatch({ type: "set", payload: { ...data.me, auth: true, accessToken: token, profile: profile.data.getStudentMyProfile.profile } });
    },
    async onError() {
      localStorage.removeItem("_chuck-38e6e39a-ab69-4aac-bb0c");
      await dispatch({ type: "reset", payload: _auth });
    },
  });

  const login = async (user: IUser) => {
    localStorage.setItem("_chuck-38e6e39a-ab69-4aac-bb0c", user.accessToken);
    // await dispatch({ type: "set", payload: { ...user, auth: true } });
    await me();
  };
  const logout = async () => {
    localStorage.removeItem("_chuck-38e6e39a-ab69-4aac-bb0c");
    await dispatch({ type: "reset", payload: _auth });
  };

  return <AuthContext.Provider value={{ ..._state, login, logout, me }}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const auth = useContext(AuthContext);
  return auth;
}

export default useAuth;
