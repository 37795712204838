import { Fragment, Key, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import EmptyLayout from "../layout/empty-layout";
import HomeLayout from "../layout/home-layout";
import { AuthGuard, ReversGuard } from "./guards";

import SuspenseScreen from "./suspense-screen";

function Router() {
  const routes: any = [
    {
      path: "/",
      layout: HomeLayout,
      routes: [{ element: lazy(() => import("../pages/home")) }],
    },
    {
      path: "/cart",
      layout: HomeLayout,
      routes: [{ element: lazy(() => import("../pages/cart")) }],
    },
    {
      path: "/checkout",
      layout: HomeLayout,
      guard: AuthGuard,
      routes: [{ element: lazy(() => import("../pages/cart/checkout")) }],
    },
    {
      path: "/package-checkout",
      layout: HomeLayout,
      guard: AuthGuard,
      routes: [
        { element: lazy(() => import("../pages/cart/packageCheckout")) },
      ],
    },
    {
      path: "/courses",
      layout: HomeLayout,
      routes: [
        {
          path: ":cid",
          element: lazy(() => import("../pages/courses/cid")),
        },
        { element: lazy(() => import("../pages/courses")) },
      ],
    },
    {
      path: "/lesson",
      layout: HomeLayout,
      guard: AuthGuard,
      routes: [
        {
          path: ":lid",
          element: lazy(() => import("../pages/courses/lesson/index")),
        },
        { element: lazy(() => import("../pages/courses")) },
      ],
    },
    {
      path: "/profile",
      layout: HomeLayout,
      guard: AuthGuard,
      routes: [
        {
          path: "payment-history",
          layout: EmptyLayout,
          routes: [
            {
              path: ":pid",
              element: lazy(() => import("../pages/profile/payments/id")),
            },
            { element: lazy(() => import("../pages/profile/payments")) },
          ],
        },
        { path: "edit", element: lazy(() => import("../pages/profile/edit")) },
        { element: lazy(() => import("../pages/profile")) },
      ],
    },
    {
      path: "/auth",
      layout: HomeLayout,
      // guard: ReversGuard,
      routes: [
        {
          path: "forget-password",
          guard: ReversGuard,
          element: lazy(() => import("../pages/auth/forget-password")),
        },
        {
          path: "login",
          guard: ReversGuard,
          element: lazy(() => import("../pages/auth/login")),
        },
        {
          path: "register",
          element: lazy(() => import("../pages/auth/register")),
        },
        { element: lazy(() => import("../pages/tutors")) },
      ],
    },
    {
      path: "/tutors",
      layout: HomeLayout,
      routes: [
        { path: ":id", element: lazy(() => import("../pages/tutors/tid")) },
        { element: lazy(() => import("../pages/tutors")) },
      ],
    },
    {
      path: "/about-us",
      layout: HomeLayout,
      routes: [
        {
          path: "results",
          element: lazy(() => import("../pages/about-us/results")),
        },
        { element: lazy(() => import("../pages/about-us")) },
      ],
    },
    {
      path: "/support",
      layout: HomeLayout,
      routes: [{ element: lazy(() => import("../pages/support")) }],
    },
    {
      path: "/ai",
      layout: HomeLayout,
      routes: [{ element: lazy(() => import("../pages/ai")) }],
    },
    { path: "*", layout: HomeLayout, element: lazy(() => import("./404")) },
  ];

  const routeRender = (
    route: {
      element: any;
      guard: any;
      layout: any;
      path: string;
      routes: any[];
    },
    i: Key | null | undefined
  ) => {
    const Element = route.element ? route.element : Fragment;
    const Guard = route.guard ? route.guard : Fragment;
    const Layout = route.layout ? route.layout : Fragment;
    return (
      <Route
        key={i}
        index={route.path ? false : true}
        path={route.path}
        element={
          <Guard>
            <Layout>
              <Suspense fallback={<SuspenseScreen />}>
                <Element />
              </Suspense>
            </Layout>
          </Guard>
        }
      >
        {route.routes && route.routes.map(routeRender)}
      </Route>
    );
  };
  return <Routes>{routes.map(routeRender)}</Routes>;
}

export default Router;
